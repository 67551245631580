import { tns } from "tiny-slider";
window.tns = tns;

// Media queries breakpoints
window.screen_xs = 480;
window.screen_xs_min = screen_xs;
window.screen_phone = screen_xs_min;
window.screen_sm = 768;
window.screen_sm_min = screen_sm;
window.screen_tablet = screen_sm_min;
window.screen_md = 992;
window.screen_md_min = screen_md;
window.screen_desktop = screen_md_min;
window.screen_lg = 1200;
window.screen_lg_min = screen_lg;
window.screen_lg_desktop = screen_lg_min;
window.screen_xs_max = (screen_sm_min - 1);
window.screen_sm_max = (screen_md_min - 1);
window.screen_md_max = (screen_lg_min - 1);
window.resizeTimer = null;
window.feature_slider = null;

function get_vw() {
    return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
}

function get_vh() {
    return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
}

window.get_vh = get_vh;
window.get_vw = get_vw;

function ajaxnav(params) {
    // ajaxnav(url, container, template, update_address, append_history)
    if (typeof params !== 'object') {
        params = {
            url: arguments[0],
            container: arguments[1],
            template: arguments[2],
            update_address: arguments[3] !== undefined ? arguments[3] : true,
            append_history: arguments[4] !== undefined ? arguments[4] : true,
        }
    }

    // uzpidom default'iniais parametrais
    params = $.extend({
        update_address: true,
        append_history: true,
        method: 'GET',
        data: {},
        error: function (data, textStatus, jqXHR) {
            $('#ajax_loader').hide();
            if (params.callback !== undefined) {
                params.callback(data, textStatus, jqXHR);
            }
        },
        success: function (data, textStatus, jqXHR) {
            $('#ajax_loader').hide();
            if (typeof data == 'object') {
                if (data.reload === true) {
                    $('#ajax_loader').show();
                    window.location.href = data.url;
                } else {
                    ajaxnav($.extend({
                        container: params.container,
                        template: params.template,
                        update_address: params.update_address,
                        append_history: params.append_history
                    }, data));
                }
            } else if (typeof data == 'string') {
                if (params.container !== undefined) {
                    var $data = $(data);
                    if (!$data.attr('id')) {
                        $data.attr('id', 'id-' + Math.random().toString(36).substr(2, 16));
                    }
                    var $previous = $(params.container).replaceWith($data);
                    init_components($data);
                    if (params.update_address) {
                        var pso = {
                            template: params.template,
                            container: params.container
                        }
                        var final_url = jqXHR.getResponseHeader('X-AJAXNAV-URL');
                        if (!final_url) final_url = params.url;
                        if (params.append_history) {
                            // window.history.replaceState(pso, '', window.location);
                            window.history.pushState(pso, '', final_url);
                        } else {
                            window.history.replaceState(pso, '', final_url);
                        }
                    }
                }
                if (params.callback !== undefined) {
                    params.callback(data, textStatus, jqXHR);
                }
            }
        }
    }, params);

    // vykdom ajax request'a
    $.ajax({
        url: params.url + (!params.url.match(/\?/) ? '?' : (!params.url.match(/&$/) ? '&' : '')) + 'display=' + params.template,
        method: params.method,
        data: params.data,
        success: params.success,
        beforeSend: function () {
            $('#ajax_loader').show();
        },
    });
}

window.ajaxnav = ajaxnav;

function init_fancybox(context) {
    $(".fancybox", context).fancybox({
        width: '70%',
        height: '100%',
        autoSize: false,
        fitToView: false
    });

    $('a[rel^="fancybox"]', context).fancybox({
        hash: false,
        btnTpl: {
            arrowLeft:
                '<button data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" role="button" title="{{PREV}}">' +
                '<i class="e-icon-arrow-left"></i>' +
                "</button>",
            arrowRight:
                '<button data-fancybox-next class="fancybox-button fancybox-button--arrow_right" role="button" title="{{NEXT}}">' +
                '<i class="e-icon-arrow-right"></i>' +
                "</button>",
        },
    });
}

function init_tooltips(context) {
    // tooltip
    $('[data-toggle="tooltip"]', context).tooltip();
}

function init_selectpicker(context) {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) && !$('.selectpicker', context).hasClass('non-mobile')) {
        $('.selectpicker', context).selectpicker('mobile');
    } else {
        $('.selectpicker', context).selectpicker();
    }
}

function init_components(context) {
    init_fancybox(context);
    init_tooltips(context);
    init_selectpicker(context);
}

window.init_components = init_components;

function init_quantity_control() {
    $(document).on('click', '.quantity-selector .plus, .quantity-selector .minus', function (e) {
        var $input = $(this).parents('.quantity-selector').find('input');
        var val = parseInt($input.val()) || 1;
        if ($(this).is('.minus')) {
            val--;
        } else {
            val++;
        }
        var min_val = $input.data('min') !== undefined ? $input.data('min') : 1;
        var max_val = $input.data('max') !== undefined ? $input.data('max') : 999;
        if (val < min_val) val = min_val;
        if (val > max_val) val = max_val;
        $input.val(val);
    });
}

function init_ajaxnav() {
    // ajaxnav
    $(document).on('click', '[data-ajaxnav="true"]:not(form)', function (e) {
        var url = this.href !== undefined ? this.href : $(this).data('ajaxnav-url');
        var callback = $(this).data('ajaxnav-callback');
        if (url) {
            e.preventDefault();
            ajaxnav({
                url: url,
                container: $(this).data('ajaxnav-container'),
                template: $(this).data('ajaxnav-template'),
                callback: function () {
                    if (typeof window[callback] == 'function') {
                        window[callback]();
                    }
                }
            });
        }
    });

    $(document).on('submit', 'form[data-ajaxnav="true"]', function (e) {
        var url = this.action ? this.action : ($(this).data('ajaxnav-url') ? $(this).data('ajaxnav-url') : '');
        var callback = $(this).data('ajaxnav-callback');
        e.preventDefault();
        ajaxnav({
            url: url,
            method: this.method ? this.method.toUpperCase() : 'GET',
            data: $(this).serialize(),
            container: $(this).data('ajaxnav-container'),
            template: $(this).data('ajaxnav-template'),
            callback: function () {
                if (typeof window[callback] == 'function') {
                    window[callback]();
                }
            }
        });
        return false;
    });

    window.onpopstate = function (event) {
        if (event.state && event.state.container && event.state.template) {
            ajaxnav(window.location.href, event.state.container, event.state.template, false);
        } else {
            $('#ajax_loader').show();
            window.location.reload();
        }
    };

}

function init_list_collapse() {
    function list_collapse($list) {
        $list.toggleClass('open');
    }

    $(document).on('click', 'div.list-collapse > .title', function (e) {
        e.preventDefault();
        list_collapse($(this).parent());
    });
    $(document).on('click', 'div.list-collapse-mobile > .title', function (e) {
        if (get_vw() <= screen_sm_max) {
            e.preventDefault();
            list_collapse($(this).parent());
        }
    });
    $(document).on('click', 'div.list-collapse-desktop > .title', function (e) {
        if (get_vw() >= screen_md_min) {
            e.preventDefault();
            list_collapse($(this).parent());
        }
    });
}

function init_list_dropdown() {
    function list_dropdown_leave($list) {
        var hovertime = $list.data('hovertime');
        var hover_delay = $list.data('hover-delay') ? $list.data('hover-delay') : 0;
        var $dropdown = $list;
        setTimeout(function () {
            // patikrinam, ar siuo metu dropdown'as tikrai neturetu buti rodomas
            if ($dropdown.data('hovertime') == hovertime) {
                $dropdown.removeClass('hover');
            }
        }, hover_delay);
    }

    $(document).on('mouseleave', 'div.list-dropdown-mobile', function () {
        if (get_vw() <= screen_sm_max) {
            list_dropdown_leave($(this));
        }
    });

    $(document).on('mouseleave', 'div.list-dropdown-desktop', function () {
        if (get_vw() >= screen_md_min) {
            list_dropdown_leave($(this));
        }
    });

    if (get_vw() >= screen_md_min) {
        $('.list-dropdown li.has_child').hover(
            function () {
                $(this).addClass('hover');

                var browser_width = $(document).outerWidth(true);
                var parent_width = $(this).width();
                var child = $(this).children('ul');
                var child_width = child.width();
                var offset_left = $(this).offset().left;
                var offset_right = browser_width - parent_width - offset_left;

                if (offset_right > child_width) {
                    child.css('left', $(this).width() + 'px');
                    child.css('right', 'auto');
                } else {
                    child.css('right', $(this).width() + 'px');
                    child.css('left', 'auto');
                }
            },
            function () {
                $(this).removeClass('hover');
            }
        );

    }
    $(document).on('mouseleave', '.list-dropdown li.hover', function () {
        if (get_vw() >= screen_md_min) {
            var parent = $(this).parent('li');
            parent.removeClass('hover');
        }
    });
}

function update_wishlist_info() {
    $.ajax({
        url: '?display=content_types/wishlist/wishlist_info',
        method: 'post',
        success: function (html) {
            $('#wishlist_info').replaceWith(html);
        }
    });
}

function init_wishlist() {
    $(document).on('click', '.add_to_wishlist', function (e) {
        e.preventDefault();
        var el = $(this);
        if (el.data('id') == "") {
            alert($alert_message);
        } else {
            $.ajax({
                url: '?display=content_types/wishlist/update_wishlist',
                data: {element_id: el.data('id')},
                method: 'post',
                dataType: 'json',
                success: function (json) {
                    if (json.status > 0) {
                        el.addClass('active');
                    } else if (json.status < 0) {
                        el.removeClass('active');
                    }
                    update_wishlist_info();
                }
            });
        }
    });
}

function doModal(content) {
    var html = '<div id="dynamicModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="confirm-modal" aria-hidden="true">';
    html += '<div class="modal-dialog">';
    html += '<div class="modal-content">';
    html += '<div class="modal-body">';
    html += '<a class="close" data-dismiss="modal">×</a>';
    html += content;
    html += '</div>'; 	// body
    html += '</div>';  	// content
    html += '</div>';  	// dialog
    html += '</div>';  	// modal
    $('body').append(html);
    $("#dynamicModal").modal();
    $("#dynamicModal").modal('show');
    $('#dynamicModal').on('hidden.bs.modal', function (e) {
        $(this).remove();
    });
}

function init_authorize() {
    $(document).on('click', '.need2login', function (e) {
        e.preventDefault();
        ajaxnav({
            url: window.location.href,
            template: 'content_types/customers/authorize',
            callback: function (html) {
                doModal(html);
            }
        });
    });
}

function rotateMenuIcon(x) {
    x.classList.toggle("change");
}

window.rotateMenuIcon = rotateMenuIcon;

function init_collection_slider() {
    $('.collections-index-listing:not(.full-list)').each(function () {
        let collectionsListing = $(this);

        if ($(window).width() < 992) {
            tns({
                container: collectionsListing.find('.list')[0],
                fixedWidth: 288,
                autoplay: false,
                loop: false,
                navContainer: collectionsListing.find('.tns-nav')[0],
                controlsText: ['<i class="e-icon-arrow-left"></i>', '<i class="e-icon-arrow-right"></i>'],
                gutter: 11,
                controls: false,
            });
        }else{
            if($(this).hasClass('display-banner')){
                tns({
                    container: collectionsListing.find('.list')[0],
                    autoplay: false,
                    loop: false,
                    navContainer: collectionsListing.find('.tns-nav')[0],
                    controlsText: ['<i class="e-icon-arrow-left"></i>', '<i class="e-icon-arrow-right"></i>'],
                    controls: false,
                    items: 3,
                    gutter: 30,
                });
            }else{
                tns({
                    container: collectionsListing.find('.list')[0],
                    autoplay: false,
                    loop: false,
                    navContainer: collectionsListing.find('.tns-nav')[0],
                    controlsText: ['<i class="e-icon-arrow-left"></i>', '<i class="e-icon-arrow-right"></i>'],
                    controls: false,
                    items: 4,
                    gutter: 30,
                });
            }
        }
    });
}

function init_product_features_slider() {
    if(!$('.product-features-items').length){
        return false;
    }

    if(feature_slider !== null){
        feature_slider.destroy();
    }

    if (get_vw() < screen_md) {
        feature_slider = tns({
            container: '.product-features-items',
            fixedWidth: 217,
            autoplay: false,
            loop: false,
            navContainer: $('.product-features .tns-nav')[0],
            controlsText: ['<i class="e-icon-arrow-left"></i>', '<i class="e-icon-arrow-right"></i>'],
            gutter: 10,
            controls: false,
        });
    }else{
        feature_slider = tns({
            container: '.product-features-items',
            items: 4,
            autoplay: false,
            loop: false,
            navContainer: $('.product-features .tns-nav')[0],
            controlsText: ['<i class="e-icon-arrow-left"></i>', '<i class="e-icon-arrow-right"></i>'],
            gutter: 30,
            controls: true,
        });
    }

    $('[data-fancybox="features"]').fancybox({
        baseClass: "features-fancybox",
        infobar: true,
        hash: false,
        arrows: false,
        afterLoad: function (instance, current) {
            var infobar = $('.features-fancybox .fancybox-inner > .fancybox-infobar').html();

            current.$content.find('.fancybox-infobar').remove();
            current.$content.append(instance.translate(current, '<div class="fancybox-infobar">' + infobar + '</div>'));
        }
    });
}

window.init_product_features_slider = init_product_features_slider;

function init_tabs_slider(context) {
    if ($('.tabs-slider', context).length) {
        $('.tabs-slider', context).each(function () {
            if ($(this).find('.inner-slider').length) {
                let element_obj = $(this);
                let inner_slider_obj = element_obj.find('.inner-slider');

                let slider_width = element_obj.outerWidth();
                let content_width = 0;

                element_obj.find('.inner-slider > *').each(function () {
                    content_width += $(this).outerWidth();
                })

                if (content_width > slider_width) {
                    if (!element_obj.find('.move-prev').length) {
                        element_obj.prepend('<div class="move-prev"></div>');
                        element_obj.prepend('<div class="move-next"></div>');
                    }
                    element_obj.find('.move-prev, .move-next').hide();
                    // element_obj.find('.move-prev').show();

                    inner_slider_obj.find('a.active').attr('data-active', 'true');
                    let init_gap = inner_slider_obj.css('left').replace("px", "");
                    let init_el = element_obj.find('[data-active="true"]');
                    let init_el_position = init_el.offset().left - element_obj.offset().left - 32 + (init_gap * -1);
                    let last_el = element_obj.find('a:last-child');

                    if (init_el_position == -32) {
                        init_el_position = 0;
                    }
                    inner_slider_obj.stop().animate({
                        left: -init_el_position
                    }, 200);

                    setTimeout(function () {
                        let init_position_right = last_el.offset().left + last_el.outerWidth() - element_obj.offset().left - element_obj.outerWidth();

                        if (init_el_position == 0) {
                            element_obj.find('.move-prev').hide();
                        } else {
                            element_obj.find('.move-prev').show();
                        }

                        if (init_position_right <= 0) {
                            element_obj.find('.move-next').hide();
                        } else {
                            element_obj.find('.move-next').show();
                        }
                    }, 300);
                    let position_left = init_el_position;
                    let position_right = last_el.offset().left - element_obj.offset().left;

                    element_obj.find('.move-next').off().on('click', function () {
                        let curr_el = element_obj.find('[data-active="true"]');
                        let next_el = curr_el.next('a');

                        let gap = inner_slider_obj.css('left').replace("px", "");
                        let next_el_position = next_el.offset().left - element_obj.offset().left - 32 + (gap * -1);
                        position_right = last_el.offset().left + last_el.outerWidth() - element_obj.offset().left - element_obj.outerWidth();

                        inner_slider_obj.stop().animate({
                            left: -next_el_position
                        }, 200);

                        setTimeout(function () {
                            position_left = inner_slider_obj.find('a:first-child').offset().left - element_obj.offset().left + (position_left * -1);
                            position_right = last_el.offset().left + last_el.outerWidth() - element_obj.offset().left - element_obj.outerWidth();

                            if (position_left != 0) {
                                element_obj.find('.move-prev').show();
                            }

                            if (position_right <= 0) {
                                element_obj.find('.move-next').hide();
                            }
                        }, 300);

                        curr_el.removeAttr('data-active');
                        next_el.attr('data-active', 'true');
                    });

                    element_obj.find('.move-prev').off().on('click', function () {
                        let curr_el = element_obj.find('[data-active="true"]');
                        let prev_el = curr_el.prev('a');
                        let first_el = element_obj.find('a:first-child');
                        let last_el = element_obj.find('a:last-child');

                        let gap = inner_slider_obj.css('left').replace("px", "");
                        let prev_el_position = (gap * -1) - prev_el.outerWidth();
                        let first_el_gap = prev_el_position + 32;

                        if (first_el_gap > -1 && first_el_gap < 1) {
                            prev_el_position = 0;
                        }

                        inner_slider_obj.stop().animate({
                            left: -prev_el_position
                        }, 200);

                        setTimeout(function () {
                            let first_el_offset = first_el.offset().left - element_obj.offset().left
                            position_right = last_el.offset().left + last_el.outerWidth() - element_obj.offset().left - element_obj.outerWidth();


                            if (position_right >= 0) {
                                element_obj.find('.move-next').show();
                            }

                            if (first_el_offset == 0) {
                                element_obj.find('.move-prev').hide();
                            }
                        }, 300);

                        curr_el.removeAttr('data-active');
                        prev_el.attr('data-active', 'true');
                    });
                } else {
                    element_obj.find('.move-prev').remove();
                    element_obj.find('.move-next').remove();
                    inner_slider_obj.find('a').each(function () {
                        $(this).removeAttr('data-active');
                    });
                    inner_slider_obj.css('left', '0px');
                }
            }
        });
    }
}

function init_article_sliders_activity(context) {
    if ($(window).width() > 767 && $('.editor_type_article_slider').length) {
        $('.editor_type_article_slider', document).each(function () {
            let parent_el = $(this);
            $(this).find('.item').on('mouseenter', function () {
                let img = $(this).data('img');
                let banner_column = parent_el.find('.banner_column');

                parent_el.find('.item').removeClass('active');
                // banner_column.removeAttr('style');
                banner_column.css('background-image', 'url("' + img + '")');
                $(this).addClass('active');
            });
        });
    }
}

function init_tooltip(el) {
    el.tooltip('show');

    setTimeout(function () {
        el.tooltip('hide');
    }, 2000);
}

function update_comparison_info(type) {
    $.ajax({
        url: '?display=content_types/comparison/comparison_info',
        method: 'post',
        success: function (html) {
            $('#comparison-info').replaceWith(html);

            if(type == 'info'){
                $('#comparison-info .compare').trigger('click');
            }
        }
    });
}

function init_comparison() {
    $(document).on('click', '.show-only-diff', function (e) {
        e.preventDefault();

        if ($(this).find('input').is(':checked')) {
            $('.show-only-diff input').prop('checked', false);
            $('#comparison-listing table tr.param').removeClass('hidden').show();
            $('#comparison-listing table').removeClass('dynamic-rows');
        } else {
            $('.show-only-diff input').prop('checked', true);

            $('#comparison-listing table tr.param').each(function () {
                let starting_param_value = '';
                let diff = false;

                $(this).find('.param-value').each(function () {
                    let param_value = $(this).text();

                    if (starting_param_value == '') {
                        starting_param_value = param_value;
                    } else {
                        if (starting_param_value !== param_value) {
                            diff = true;
                            return;
                        }
                    }
                });

                if (!diff) {
                    $(this).addClass('hidden').hide();
                }
            });

            $('#comparison-listing table').addClass('dynamic-rows');
            $('#comparison-listing table tbody tr.param:not(.hidden):visible:even').addClass('even');
        }
    });

    $(document).on('click', '.add-to-comparison, .remove-from-comparison', function (e) {
        e.preventDefault();
        e.stopPropagation();
        let el = $(this);
        let id = el.data('id');
        let type = el.data('type');
        if(typeof type === 'undefined'){
            type = '';
        }

        if (el.data('id') !== "") {
            $.ajax({
                url: '?display=content_types/comparison/update_comparison',
                data: {element_id: id, type: type},
                method: 'post',
                dataType: 'json',
                success: function (json) {
                    if (json.status > 0) {
                        el.addClass('active');
                    } else if (json.status < 0) {
                        el.removeClass('active');
                    } else if (json.status === 0){
                        return init_tooltip(el);
                    }

                    update_comparison_info(type);

                    if(type == 'info'){
                        $('.product-element .add-to-comparison[data-id="'+ id +'"]').removeClass('active');
                    }

                    if (el.hasClass('remove-comparison')) {
                        ajaxnav({
                            url: '',
                            data: {show_only_diff: $('.show-only-diff input').is(':checked') ? 1 : 0},
                            container: '#comparison-listing',
                            template: 'content_types/comparison/listing',
                            update_address: false,
                        });
                    }
                }
            });
        }
    });
}

function init_product_set() {
    let currencySymbol = $('#products-set').data('currency-symbol');
    let price = 0;
    let oldPrice = 0;
    let discount_percentage;

    $('#products-set .item.active:not(.disabled)').each(function () {
        price += parseFloat($(this).data('price'));
        oldPrice += parseFloat($(this).data('old-price'));
    });

    discount_percentage = ((price / oldPrice) - 1) * 100;
    discount_percentage = discount_percentage.toFixed(1).replace('.', ',') + ' %';

    $('#products-set .set-total-price-wrp .price').text((price ? price.toFixed(2).replace('.', ',') : 0) + ' ' + currencySymbol);

    if (oldPrice > 0) {
        $('#products-set .set-total-price-wrp .old-price').text((oldPrice ? oldPrice.toFixed(2).replace('.', ',') : 0) + ' ' + currencySymbol);
        $('#products-set .set-total-price-wrp .old-price').show();

        $('#products-set .set-total-price-wrp .tag-wrp .text').text(discount_percentage);
        $('#products-set .set-total-price-wrp .tag-wrp').show();
    } else {
        $('#products-set .set-total-price-wrp .old-price').hide();
        $('#products-set .set-total-price-wrp .tag-wrp').hide();
    }

    if (oldPrice == price) {
        $('#products-set .set-total-price-wrp .old-price').hide();
        $('#products-set .set-total-price-wrp .tag-wrp').hide();
    } else {
        $('#products-set .set-total-price-wrp .old-price').text((oldPrice ? oldPrice.toFixed(2).replace('.', ',') : 0) + ' ' + currencySymbol);
        $('#products-set .set-total-price-wrp .old-price').show();

        $('#products-set .set-total-price-wrp .tag-wrp .text').text(discount_percentage);
        $('#products-set .set-total-price-wrp .tag-wrp').show();
    }

    $('#products-set .set-total-price-wrp .button').attr('disabled', price === 0);

    if (price === 0) {
        $('#products-set .set-total-price-wrp .button').addClass('disabled');
    } else {
        $('#products-set .set-total-price-wrp .button').removeClass('disabled');
    }
}

function position_footer() {
    $('#content_wrapper').css('min-height', $(window).height() - $('#head').outerHeight() - $('#footer').outerHeight() - $('#footer_social').outerHeight() + 'px');
}

function init_mega_menu(){
    $(document).on('click', '.js-toggle-mega-menu, #mega-menu-overlay', function (e) {
        e.preventDefault();
        $('.menu-bars').toggleClass('active');
        $('#mega-menu-mobile').toggleClass('active');
        $('.submenu-wrp').removeClass('active');
    });

    $('.js-toggle-dropdown').click(function (e) {
        e.preventDefault();
        $(this).closest('.sub').find('> .submenu-wrp').toggleClass('active');
    });

    $(document).on('click', '#mega-menu-desktop-overlay', function (e) {
        e.preventDefault();
        $('#site-header .main-menu a').removeClass('active');
        $('#mega-menu-desktop').removeClass('active');
    });

    $(document).on('click', '#site-header .main-menu .menu-item a', function (e) {
        e.preventDefault();
        let target = $(this).data('target');

        if($(this).hasClass('active')){
            $(this).removeClass('active');
            $('#mega-menu-desktop').removeClass('active');
        }else{
            $('#site-header .main-menu a').removeClass('active');
            $('.submenu-wrp').removeClass('active');

            $(this).addClass('active');
            $(target).addClass('active');
            $('#mega-menu-desktop').addClass('active');
        }
    });

    $('#mega-menu-desktop .menu-lvl2 [data-target]').hover(function () {
        let target = $(this).data('target');
        let parent = $(this).closest('#mega-menu-desktop');
        let submenu_wrp = $(this).closest('.submenu-wrp');

        submenu_wrp.find('.menu-lvl2 a').removeClass('active');
        submenu_wrp.find('.menu-lvl3 ul').removeClass('active');
        submenu_wrp.find('.menu-banner').removeClass('active');
        submenu_wrp.find('.menu-lvl3').removeClass('multi-cols');

        $(this).addClass('active');

        if($(this).hasClass('multi-cols')){
            submenu_wrp.find('.menu-lvl3').addClass('multi-cols');
        }

        parent.find('.subsubmenu-' + target).addClass('active');
        parent.find('#submenu-banner-' + target).addClass('active');
    });
}

function scrollToElement(el) {
    if (!$(el).length) return;

    let mainNavH = $('#site-header').outerHeight();
    if (typeof mainNavH == 'undefined') mainNavH = 0;

    $('html, body').stop().animate({
        'scrollTop': $(el).offset().top - mainNavH
    }, 500, 'swing');
}

function init_product_detailed_collections(){
    if($(window).width() < screen_md){
        $('.product-detailed-collection').each(function () {
            let collectionListing = $(this);

            tns({
                container: collectionListing.find('.list')[0],
                fixedWidth: 288,
                autoplay: false,
                loop: false,
                navContainer: collectionListing.find('.tns-nav')[0],
                controlsText: ['<i class="e-icon-arrow-left"></i>', '<i class="e-icon-arrow-right"></i>'],
                gutter: 11,
                controls: false,
            });
        });
    }

    $(document).on('click', '.product-detailed-collection .show-all', function (e) {
        e.preventDefault();

        $(this).addClass('d-none');
        $(this).closest('.product-detailed-collection').find('.list-desktop').removeClass('limited-items');
    })
}

$(function () {
    var header = document.getElementById("mainHeader");
    var content_layout = document.getElementById("content_layout");

    init_components(document);
    init_quantity_control();
    init_ajaxnav();
    init_list_collapse();
    init_list_dropdown();
    init_authorize();
    init_wishlist();
    init_collection_slider();
    init_tabs_slider(document);
    init_article_sliders_activity(document);
    init_comparison();
    position_footer();
    init_mega_menu();
    init_product_detailed_collections();

    $(document).on('click', '.close-btn, .continue-shopping, .added2cart-overlay', function (e) {
        e.preventDefault();
        $('.products-added2cart').remove();
        $('.added2cart-overlay').remove();
    });

    $(document).on('click', '.act-show-technical', function (e) {
        e.preventDefault();
        if($(this).hasClass('active')){
            scrollToElement('.technical-details');
        }

        $(this).toggleClass('active');
        $('.technical-details-table-wrp').toggleClass('limited-height');
    });

    $(document).on('click', '#products-photos .thumbs .thumb', function () {
        var idx = $("#products-photos .thumbs .thumb").index($(this));
        $("#products-photos .thumbs .thumb").removeClass('active');
        $(this).addClass('active');
        $("#products-photos .main-photo a").removeClass('active');
        $("#products-photos .main-photo a:eq("+idx+")").addClass('active');
    });

    var bannerCol3Carousel = $('.banner-col3-carousel');

    if (get_vw() < screen_md && bannerCol3Carousel.length) {
        bannerCol3Carousel.each(function () {
            tns({
                container: $(this).find('.list')[0],
                items: 1,
                autoplay: false,
                loop: false,
                navContainer: $(this).find('.tns-nav')[0],
                controlsText: ['<i class="e-icon-arrow-left"></i>', '<i class="e-icon-arrow-right"></i>'],
                gutter: 0,
                controls: false,
                responsive: {
                    768: {
                        items: 2,
                        gutter: 8,
                    },
                }
            });
        });
    }

    if (get_vw() < screen_md && $('.hero-2-columns').length) {
        tns({
            container: '.hero-2-columns',
            items: 1,
            autoplay: false,
            loop: true,
            navContainer: '.hero-2cols-wrp .tns-nav',
            controlsText: ['<i class="e-icon-arrow-left"></i>', '<i class="e-icon-arrow-right"></i>'],
            gutter: 0,
            controls: false,
        });
    }

    if ($('.editor_type_categories_slider').length) {
        tns({
            container: '.editor_type_categories_slider .list',
            items: 1,
            slideBy: 'page',
            autoplay: false,
            controls: false,
            loop: false,
            controlsText: ['<i class="e-icon-arrow-left"></i>', '<i class="e-icon-arrow-right"></i>'],
            autoWidth: true,
            gutter: 8,
            navContainer: '.editor_type_categories_slider .tns-nav',
            responsive: {
                992: {
                    gutter: 10,
                    controls: true,
                },
            }
        });
    }

    if ($('.editor_type_banner_col4').length && get_vw() < screen_md) {
        tns({
            container: '.editor_type_banner_col4 .list',
            items: 1,
            slideBy: 'page',
            autoplay: false,
            controls: false,
            loop: false,
            fixedWidth: 240,
            gutter: 8,
            navContainer: '.editor_type_banner_col4 .tns-nav',
        });
    }

    $(document).on('change', '.coupon-block input[name="coupon_no_greetings"]', function () {
        let block = $('.coupon-block .greetings-form');

        if ($(this).is(':checked')) {
            block.hide();
            block.find('input, textarea').attr('disabled', true);
        } else {
            block.show();
            block.find('input, textarea').removeAttr('disabled');
        }
    });

    $(window).on('resize', function () {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
            init_tabs_slider(document);

            if (get_vw() > 991) {
                $('nav').removeClass('nav-mobile');
                $('body').removeClass('main-nav-active');
            }
        }, 100);
    });

    $(document).on('click', '.js-toggle-sorting', function (e) {
        e.preventDefault();
        $('#sorting-mobile').toggleClass('active');
    });

    $(document).on('click', '#quick-search .clear-input', function (e) {
        e.preventDefault();

        $('#quick-search input').val('');
        $('#search-suggestions-results').empty();
    });

    $(document).on('click', '.quick-search-toggle', function (e) {
        e.preventDefault();
        $('#mega-menu-desktop').removeClass('active');
        $('#site-header .main-menu a').removeClass('active');
        $('#quick-search').toggleClass('active');

        $('#quick-search input').val('');
        $('#search-suggestions-results').empty();

        if($('#quick-search').hasClass('active')){
            setTimeout(function () {
                $('#quick-search input').focus();
            }, 100);
        }
    });

    var search_delay = null;
    $("#quick-search input[name='search']").keyup(function () {
        let val = $(this).val();
        if (val.length > 2) {
            if (search_delay != null) clearTimeout(search_delay);
            search_delay = setTimeout(function () {
                $("#search-suggestions-results").html(loader);
                $('#quick-search').addClass('active');

                let search_request = $.ajax({
                    type: 'POST',
                    url: '?display=content_types/search/suggestions.tpl',
                    data: {q: val},
                    success: function (data) {
                        $("#search-suggestions-results").html(data);
                    }
                });
            }, 700);
        } else {
            if (val.length > 0) {
                $("#search-suggestions-results").html(moreChars);
                $('#quick-search').addClass('active');
            } else {
                $('#quick-search').removeClass('active');
            }
        }
    });

    $(document).on('click', '#products-set .item:not(.disabled) .checkbox-item', function (e) {
        e.preventDefault();
        let parent = $(this).closest('.item');
        parent.toggleClass('active');
        parent.find('input').attr('checked', !parent.find('input').attr('checked'));
        init_product_set();
    });

    $(document).on('click', '.accordion-styled .card-header button', function (e) {
        e.stopImmediatePropagation();

        $(this).toggleClass('collapsed');
        $(this).parent().parent().next().slideToggle(350);
    });

    $(document).on('click', "[data-dl-info]:not(.add_to_wishlist)", function (e) {
        var info = $(this).data('dl-info');
        var event = $(this).data('dl-event');
        var crm_id = $(this).data('dl-crm-id');

        if (event == 'begin_checkout') {
            dl_begin_checkout(info, crm_id);
        } else if (event == 'add_shipping_info') {
            let shipping_method = $(this).data('dl_shipping');

            if (shipping_method) {
                info.shipping_tier = shipping_method;
                dl_add_shipping_info(info, crm_id);
            }
        } else if (event == 'add_payment_info') {
            let payment_method = $(this).data('dl-payment');

            if (payment_method) {
                info.payment_type = payment_method;
                dl_add_payment_info(info, crm_id);
            }
        } else {
            dl_select_item(info, crm_id);
        }
    });
});

// datalayer select_item
function dl_select_item(productObj, crm_id) {
    let obj = {
        event: "select_item",
        ecommerce: {
            items: [                                           // required
                productObj
            ]
        }
    };
    if (typeof crm_id != 'undefined') {
        obj['user_id'] = crm_id;
        obj['crm_id'] = crm_id;
    }

    dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
    dataLayer.push(obj);
}

function dl_begin_checkout(ecommerceObj, crm_id) {
    let obj = {
        event: "begin_checkout",
        ecommerce: ecommerceObj
    };
    if (typeof crm_id != 'undefined') {
        obj['user_id'] = crm_id;
        obj['crm_id'] = crm_id;
    }

    dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
    dataLayer.push(obj);
}

function dl_add_shipping_info(ecommerceObj, crm_id) {
    let obj = {
        event: "add_shipping_info",
        ecommerce: ecommerceObj
    };
    if (typeof crm_id != 'undefined') {
        obj['user_id'] = crm_id;
        obj['crm_id'] = crm_id;
    }

    dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
    dataLayer.push(obj);

}

function dl_add_payment_info(ecommerceObj, crm_id) {
    let obj = {
        event: "add_payment_info",
        ecommerce: ecommerceObj
    };
    if (typeof crm_id != 'undefined') {
        obj['user_id'] = crm_id;
        obj['crm_id'] = crm_id;
    }

    dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
    dataLayer.push(obj);
}